@import '../../styles/variables';

.about {
    background-color: $bg-color-about;
    display: flex;
    flex-direction: column;
    color: $hover-txt-color;

    h1 {
        text-align: center;
        margin-top: 4%;
        font-size: 2em;
    }

    .content {
        display: flex;
        flex-direction: row;
        position: relative;

        .imgAbsolute {
            max-width: 400px;
            max-height: 393px;
            min-width: 400px;
        }

        p {
            margin-left: 10%;
            line-height: 150%;
            margin-top: 4%;
            margin-right: 10%;
            font-size: 1em;
        }
    }

    @media (max-width: 838px) { 

        h1 {
            font-size: 1.5em;
        }
        .imgAbsolute {display: none;}
        p {
            margin-bottom: 10%;
        }
    }  
}
