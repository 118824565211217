@import '../../styles/variables';

.products {
  padding: 0em 1em;
  background-color: $nav-color;
  height: 100vh;

  @media (max-width: 838px) { 
    height: 80vh;
}  

  h1 {
    text-align: center;
    color: $txt-color;
    margin-bottom: 2em;
    padding-top: 4%;
    font-size: 2em;

    @media (max-width: 838px) { 
      font-size: 1.5em;
  }  
  }

  .slick-arrow::before {
    font-size: 50px;
    color: $txt-color;

    @media (max-width: 838px) { 
      font-size: 30px;
    }  
  }

  .slick-prev, .slick-next {
    position: absolute;
    top: 50%; /* Centrer verticalement */
    transform: translateY(-50%); /* Assurez-vous que le centre de la flèche est aligné avec le centre vertical */
    z-index: 1;
  }

  .slick-prev {
    left: 1%;
  }

  .slick-next {
    right: 4%; /* Ajuster selon les besoins */
    @media (max-width: 838px) { 
    right: 10px;
  }  
  }

  .navigationButtons {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      min-width: 140px;
      margin: 0px 10px;
      margin-top: 2%;
      margin-bottom: 2%;
      background-color: $bg-color;
      color: $nav-color2;
      padding: 5px 20px;
      border: 2.5px solid #FFF;
      border-radius: 90px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      font-size: 1.3em;
      transition: background-color 0.3s, transform 0.3s;
      font-weight: 400;

      @media (max-width: 838px) { 
        display: none;
    }  

      &:hover {
        background-color: $earth-orange;
      }
      &.activeButton {
        background-color: $earth-orange;
      }
    }
  }

  .slick-slider {
    .slick-list {
      margin: 0% 0%;
      margin-left: 4%;
      margin-right: 6%;
      @media (max-width: 838px) {
    }  
      .slick-slide {
        opacity: 0.5;
        transition: opacity 0.6s ease, transform 0.6s ease;
        touch-action: manipulation;
        &.slick-center {
          opacity: 1;
          padding-bottom: 20px;
          .card {
            opacity: 1;
            transform: scale(1.1);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
            @media (max-width: 838px) {
              width: 70%;
              margin: 10% auto; // Centrage horizontal de la card
              opacity: 1; // Assurez-vous que la card est totalement visible
            } 
          }
        }
      }
    }
  }
}

