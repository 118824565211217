@import '../../styles/variables';

.footer {
  background-color: $bg-color;
  padding: 1em;
  color: $hover-txt-color;

  .footerContent {
    display: flex;
    justify-content: space-around;

    .footerSection1, .footerSection2 {
      margin-right: 20px;

      a {
        color: $nav-color;
      }
      
      h3 {
        margin-bottom: 1em;
      }

      p, ul {
        line-height: 170%;
      }

      li {
        display: flex;
        align-items: center;

        img {
          width: 50px;
          height: auto;
          margin-left: 0.5em;
        }
      }
    }

    @media (max-width: 838px) { 
      display: flex;
     flex-direction: column;
     margin-top: 5%;

     .footerSection1, .footerSection2 {
      margin: 3% 3%; 
    }
  } 
  }

  .footerBottom {
    text-align: center;
  }
}
