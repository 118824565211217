@import '../../styles/variables'; 

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home {
  background-image: url('../../images/home-bg.webp');
  background-size: cover;
  background-position: top;
  min-height: 100vh;
  margin-top: 51.36px;

  &::before {
    content: '';
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $earth-brown; 
    opacity: 0.2;
    z-index: 2; 
    height: 100%;
    margin-top: 51.36px;
  }
  
  .container {
    animation: fadeIn 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative; 
    z-index: 3; 

    h1 {
      font-size: 3em;
      margin-top: 6%;
      text-align: center;
      width: 80%;
      font-family: 'Ubuntu';
      font-weight: 700;

      @media (max-width: 838px) { 
        font-size: 2em;
    }  
    }

    .subtitleAndButton {
      align-self: flex-start; 
      margin-left: 15%; 
      margin-right: 15%;
      display: flex;
      flex-direction: column; 
      @media (max-width: 838px) { 
        margin-left: 10%;
        margin-right: 10%;
    }  
  
    p {
      text-align: left;
      font-size: 2em;
      margin-top: 4%;
      width: 100%;

      @media (max-width: 838px) { 
        font-size: 1.5em;
        margin-top: 15%;
    }  
    }
  
    a {
      width: 215px;
      margin-top: 4%;
      background-color: $nav-color;
      color: white;
      padding: 10px 20px;
      border: 2.5px solid #FFF;
      border-radius: 90px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      font-size: 1.5em;
      transition: background-color 0.3s, transform 0.3s;

      @media (max-width: 838px) { 
        font-size: 1.5em;
        margin-top: 20%;
    }  

      &:hover {
        background-color: $earth-orange;
      }
    }
  }
  }
  .down-button {
    position: absolute;
    top: 87%;
    width: auto;
    left: 50%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%); 
    
    .icon img {
      height: 2.5em;
      width: auto;
      margin-bottom: 5px;
      cursor: pointer;
      margin-top: 15px;
      transition: transform 0.4s ease-in-out;
    }
  
    .text {
      opacity: 0;
      transition: opacity 0.3s;
    }
  
    &:hover .text {
      opacity: 1;
    }
  
    &:hover .icon img {
      transform: translateY(10px);
    }
  }
  
}
