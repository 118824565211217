@import './_variables'; 
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}


body, html {
    height: 100%; 
    font-weight: 300;
    font-family: 'Ubuntu', sans-serif;
    background-color: $mainbg;
    color: $txt-color;
    touch-action: manipulation;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%; 
}

main {
    flex: 1; 
}

h1 {
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

img {
    pointer-events: none;
  }